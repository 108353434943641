.popperBackDrop {
  position: fixed;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 8;
  left: 0;
  top: 0;
}

.drop-down {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  background: #ffffff;
  position: relative;
  min-width: 150px;
  display: block;

  li {
    padding: 8px 15px;
    cursor: pointer;
    display: block;

    &:hover {
      background: #efefef;
    }
  }
}
