.lang-switcher {

  p {
    font-size: 18px;
    display: block;
    color: #707070;

    @include mobile {
      font-size: 16px;
    }
  }

  .lang-options {
    justify-content: flex-start;
    flex-direction: row;
    padding-top: 10px;
    display: flex;

    @include mobile{
      justify-content: space-between;
    }

    > * {
      display: block;
      max-width: 60px;
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      @include mobile {
        max-width: 80px;
        margin: 0;
      }
    }
  }
}
