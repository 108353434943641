.sectionSeparator {
  border-top: 4px #E8E7EE solid;
  padding-top: 15px;

  .groupTitle {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px;

    @include mobile {
      font-size: 16px;
    }
  }

  .groupDescription {
    margin-bottom: 10px;
    line-height: 22px;
    font-size: 18px;

    @include mobile {
      font-size: 14px;
    }
  }
}

.infoQuestionIcon {
  margin-left: 5px;
  margin-top: -1px;
  height: 16px;
  width: 16px;
}
