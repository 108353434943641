.link-status {
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100vh;
  width: 100vw;

  border-bottom-width: 10px;
  border-bottom-style: solid;

  .link-status-logo {
    padding-top: 50px;

    img {
      max-width: 300px;
      height: auto;
      width: 100%;
    }
  }

  .link-icon-container {
    flex-direction: column;
    align-items: center;
    display: flex;

    @include mobile {
      padding: 0 25px;
    }

    > svg {
      margin-bottom: 30px;
    }

    strong {
      font-weight: normal;
      font-size: 30px;

      @include mobile {
        text-align: center;
        font-size: 24px;
      }
    }

    > span {
      margin-bottom: 30px;
      border-radius: 100%;
      text-align: center;
      position: relative;
      display: block;
      height: 94px;
      width: 94px;

      svg {
        position: absolute;
        max-width: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .link-separator {
    padding-bottom: 110px;
  }
}
