.has-checkbox {
  line-height: 0;
  font-size: 0;
}

.styled-checkbox {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 16px;
  cursor: pointer;

  input {
    display: none;
  }

  > span {
    display: inline-block;
    text-align: center;
    margin-right: 10px;
    border: 1px solid;
    line-height: 24px;
    overflow: hidden;
    height: 26px;
    width: 26px;

    svg {
      vertical-align: middle;
      max-width: 70%;
    }
  }
}
