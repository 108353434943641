.submissions-page {

  .submissions-container {
    justify-content: space-between;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px 0;
    display: flex;

    @include mobile {
      margin: 70px 30px 0;
    }

    .container {
      width: 100%;
    }

    @include tablet {
      width: calc(100% - 110px);
      margin-left: 90px;
    }

    .back-to-patient {
      padding: 20px 0;

      a {
        text-decoration: none;
        border: 1px solid;
        padding: 5px 10px;
        font-size: 16px;
        outline: none;
      }
    }
    
    .submissions-table-container {

      @include mobile {
        width: calc(100vw - 60px);
        overflow: auto;
      }
    }

    .submissions-pagination {
      margin-top: 15px;
    }
  }
}
