.loading {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  width: 100vw;

  .loading-brand {
    flex-direction: column;
    align-items: center;
    display: flex;

    img {
      margin-bottom: 40px;
      max-width: 300px;
      height: auto;
      width: 100%;
    }
  }

  @keyframes ldio-x0v962a89vn {
    0% { transform: rotate(0deg) }
    50% { transform: rotate(180deg) }
    100% { transform: rotate(360deg) }
  }
  .ldio-x0v962a89vn div {
    position: absolute;
    animation: ldio-x0v962a89vn 1s linear infinite;
    width: 80px;
    height: 80px;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    box-shadow: 0 2px 0 0 #222222;
    transform-origin: 40px 41px;
  }
  .loadingio-spinner-eclipse-82liglvi7sc {
    width: 58px;
    height: 58px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
  }
  .ldio-x0v962a89vn {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.58);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-x0v962a89vn div { box-sizing: content-box; }
}
