.patient-info {

  form {
    margin-left: 70px;

    @include tablet {
      width: calc(100% - 110px);
      margin-left: 90px;
    }

    @include mobile {
      margin-left: 0;
    }
  }

  .patient-info-container {
    justify-content: space-between;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px 0;
    display: flex;

    @include mobile {
      margin: 70px 30px 0;
    }

    .welcome-bar {
      justify-content: space-between;
      align-items: center;
      display: flex;

      @include mobile {
        margin-bottom: 25px;
      }

      .logout-button {
        @include mobile {
          display: none;
        }
      }
    }

    .button-submission {
      text-decoration: none;
      display: inline-block;
      margin-right: 15px;
      border: 1px solid;
      padding: 5px 10px;
      background: none;
      cursor: pointer;
      font-size: 16px;
      outline: none;

      @include mobile {
        margin-right: 0;
      }
    }

    .patient-info-actions {
      justify-content: space-between;
      flex-direction: row;
      margin-top: 20px;
      display: flex;

      @include mobile {
        flex-direction: column;
      }

      .patient-actions,
      .form-actions {
        flex-direction: row;
        display: flex;

        > * {
          width: 170px;

          @include tablet {
            width: 150px;
          }

          @include mobile {
            width: 100%;
          }
        }
      }

      .patient-actions {

        @include mobile {
          justify-content: space-between;
          flex-direction: column;
        }

        > * {
          margin-right: 15px;

          @include mobile {
            margin: 0 0 15px;
          }
        }
      }

      .form-actions {
        > * {
          margin-left: 15px;

          @include mobile {
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

#generatedQR {
  display: none;
}
