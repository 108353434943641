.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  height: 100vh;

  h1 {
    font-size: 30px;
    display: block;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
  }
}
