.form-layout-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;

  justify-content: space-between;
  padding: 0 20px;

  .header {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
    z-index: 99999;
    height: 60px;

    .headerLeft {
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      .logo {
        margin-right: 16px;
        width: 317px;
        height: 50px;

        @include mobile {
          margin-right: 0;
          height: auto;
          width: 80%;
        }
      }
    }
  }

  .content {
    display: flex;
    flex: 1;
    transition: .6s ease;

    &.contentOut {
      opacity: 0;
    }

    &.contentIn {
      opacity: 1;
    }
  }

  .footer {
    background-color: #F4F4F4;
    padding-bottom: 15px;
    margin: 0 -20px;
    width: 100vw;

    .progressBar {
      background-color: #C4C4C4;
      height: 5px;
    }

    .footerContainer {
      padding: 15px 20px 0;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      display: flex;
    }
  }
}
