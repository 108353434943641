.Modal {
  height: 100%;
  width: 100%;
  padding: 20px;
  outline: none;

  background-color: #ffffff;
  overflow: auto;
}

.closeModal {
  display: none;
}

@include mobile {
  .closeModal {
    border: 1px #bbb solid;
    background: #ffffff;
    border-radius: 30px;
    position: absolute;
    overflow: hidden;
    z-index: 99999;
    display: block;
    outline: none;
    height: 30px;
    width: 30px;
    right: 15px;
  }
}

.Overlay {
  position: fixed;
  z-index: 999;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.4);
}

@media (min-width: 768px) and (max-width: 1279px) {
  .Modal {
    width: 60%;
    height: auto;
    max-width: 400px;
  }
}

@media (min-width: 1280px) {
  .Modal {
    height: auto;
    width: auto;

    max-width: 400px;
  }
}

.qr-scan-container {
  min-width: 300px;
  max-width: 400px;
  height: 100%;
  width: 100%;

  @include tablet {
    min-height: auto;
    min-width: auto;
  }

  @include mobile {
    max-width: 100%;
  }

  .barcode-scanner-input {
    border: 1px #bbb solid;
    display: block;
    outline: none;
    padding: 10px;
    height: 40px;
    width: 100%;
  }

  .alternative-scanning {
    margin-top: 10px;
    display: block;

    button {
      background: #222222;
      text-align: center;
      padding: 10px 20px;
      cursor: pointer;
      font-size: 16px;
      color: #ffffff;
      outline: none;
      border: none;
      width: 100%;

      &:hover {
        background: lighten(#222222, 10%);
      }
    }
  }
}

.generate-link {
  h2 {
    margin-bottom: 20px;
    font-size: 26px;
    display: block;
  }

  .link-type {
    margin-bottom: 20px;

    p {
      margin: 20px 0 10px;
      display: block;
    }

    .button-types {
      justify-content: space-between;
      flex-direction: row;
      display: flex;

      > * {
        flex: 0.47;
      }
    }
  }
}


.continue-options {
  text-align: center;

  h2 {
    margin-bottom: 20px;
    font-size: 26px;
    display: block;
  }

  button {
    min-width: 300px;
    margin: 5px 0;
  }
}

.terminate-modal {
  min-width: 300px;
  padding: 20px;
}


.view-submission {
  max-height: 80vh;
  overflow: auto;
  width: 360px;

  h2 {
    margin-bottom: 20px;
    font-size: 26px;
    display: block;
  }

  .submission-form-group {
    h3 {
      margin: 5px 0;
      font-weight: bold;
      font-size: 16px;
      display: block;
    }

    p {
      word-break: break-word;
      background: #efefef;
      display: block;
      padding: 4px;
    }
  }
}
