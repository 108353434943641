.consent-header {
  display: flex;
  flex-direction: column;
  flex: 0.2;
  justify-content: flex-start;

  .title {
    position: relative;
    top: auto;
    margin-top: 5px;
    left: auto;
  }

  @include mobile {
    .logo {
      text-align: center;

      img {
        max-height: none;
        max-width: 80%;
      }
    }
  }
}

.consent-container {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 40px 0;

  @include mobile {
    padding: 30px 20px;
  }

  .lang-switcher {
    margin-bottom: 50px;
    text-align: center;

    .lang-options {
      justify-content: center;
      @include mobile {
        justify-content: space-between;
      }
    }

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .consent-welcome {
    max-width: 800px;
    margin: 0 auto 50px;

    @include mobile {
      margin: 0 auto 10px;
    }

    p {
      margin-bottom: 15px;
      font-size: 18px;
      color: #2C2727;
      display: block;

      @include mobile {
        font-size: 16px;
      }
    }
  }

  .consent-actions {
    > p {
      font-size: 18px;
      display: block;
      color: #707070;

      @include mobile {
        font-size: 16px;
      }
    }

    .consent-buttons {
      justify-content: center;
      flex-direction: row;
      padding-top: 10px;
      display: flex;

      > * {
        max-width: 170px;
        margin: 0 5px;
      }
    }

    .survey-continue {
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;
      display: flex;

      p {
        margin-bottom: 15px;
        font-size: 18px;
        color: #2C2727;
        display: block;

        @include mobile {
          font-size: 16px;
        }
      }

      button {
        align-self: center;
        max-width: 170px;
      }
    }
  }
}
