.stickyNav:after {
  content: '';
  display: block;
  height: 60px;
}

.questionnaire-navigation {
  align-items: center;
  position: fixed;
  display: flex;
  height: 60px;
  width: 100%;
  bottom: 0;
  left: 0;

  @include mobile {
    position: absolute;
    bottom: 0;
  }

  .container {
    border-top: 1px #D4DBDE solid;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: inherit;
    display: flex;
    padding: 0 10px;

    > button {
      align-items: center;
      position: relative;
      background: none;
      cursor: pointer;
      font-size: 18px;
      color: #2C2727;
      display: flex;
      outline: none;
      border: none;
      z-index: 9;

      &[disabled] {
        opacity: .5;
      }

      svg + span,
      span + svg {
        margin-left: 10px;
      }

      span {
        line-height: 1;
        @include mobile {
          display: none;
        }
      }
    }
  }

  .pagination {
    justify-content: center;
    flex-direction: row;
    align-items: center;
    position: absolute;
    display: flex;
    height: 4px;
    background: #37ab49;
    left: 0;
    top: 0;
  }
}
