.questionnaire-form-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: -0.5%;
  padding-bottom: 10px;
  margin-bottom: 20px;
  flex-wrap: nowrap;
  flex: 1;

  @include mobile {
    margin-bottom: 0;
  }
}
