@import "./questionnaire-navigation";

.questionnaire-header {
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-top: 20px;
  flex-wrap: wrap;
  display: flex;

  @include mobile {
    padding: 20px 15px 0;
    div:nth-child(2) {
      flex: 1;
      order: 1;
    }
  }

  .logo {

    img {
      max-height: 50px;

      @include mobile {
        max-height: 80px;
      }
    }
  }

  .title {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 30px;
    left: 0;

    @include mobile {
      position: relative;
      margin-top: 20px;
      transform: none;
      left: auto;
      top: auto;
    }

    h1 {
      font-size: 36px;
      line-height: 1;

      @include mobile {
        font-size: 24px;
      }
    }

    p {
      margin-top: 20px;
      font-size: 18px;
      display: block;
      color: #2C2727;
    }
  }
}

.questionnaire-container {
  align-items: center;
  display: flex;

  @include mobile {
    margin-bottom: 20px;
  }

  .type-1-questionnaire {
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;

    button {
      padding: 10px 15px;
      max-width: 600px;
      min-height: 54px;
      margin: 10px 0;
      height: auto;
      width: 100%;

      @include mobile {
        padding: 6px 10px;
        min-height: 46px;
        margin: 7px 0;
      }
    }
  }

  .type-2-questionnaire {
    width: 100%;

    .form-group {
      align-items: flex-end;
      flex-direction: row;
      margin: 0 -10px;
      display: flex;

      button {
        margin: 0 10px;
        @include mobile {
          margin: 0 5px;
        }
      }
    }

    .form-group-limits {
      justify-content: space-between;
      border-top: 1px solid;
      flex-direction: row;
      padding-top: 15px;
      margin-top: 20px;
      display: flex;

      span:last-child {
        text-align: right;
      }
    }

    > p {
      text-align: center;
      margin-top: 30px;
      font-size: 18px;
      display: block;
    }
  }

  .type-3-questionnaire {
    justify-content: space-between;
    min-height: calc(100vh - 270px);
    align-items: center;
    flex-direction: row;
    margin-top: 0;
    display: flex;
    width: 100%;

    @include mobile {
      min-height: auto;
      flex-direction: column;
      margin-bottom: 30px;
      width: 100%;
    }

    .content {
      flex-direction: column;
      padding-right: 15px;
      display: flex;
      flex: 0.5;

      @include mobile {
        padding-right: 0;
      }

      h1 {
        margin-bottom: 10px;
        font-weight: normal;
        font-size: 36px;
        display: block;

        @include mobile {
          text-align: center;
        }
      }

      p {
        font-size: 18px;

        @include mobile {
          font-size: 16px;
        }
      }
    }

    .content-slider {
      flex-direction: row;
      padding-left: 15px;
      display: flex;
      height: 100%;
      flex: 0.5;
      min-height: inherit;

      @include mobile {
        flex-direction: column;
        margin-top: 15px;
        height: 300px;
        flex: 0.7 1;
        padding-left: 0;
        width: 100%;
      }
    }

    .slider-component {
      flex-direction: row;
      position: relative;
      display: flex;
      height: 100%;
      min-height: inherit;

      @include mobile {
        flex-direction: column;
        height: auto;
        width: 100%;
      }

      .slider-ticks {
        position: relative;
        width: 20px;

        @include mobile {
          width: 100%;
          height: 20px;
        }


        .slider-tick {
          position: absolute;
          font-size: 20px;
          right: 0;
        }
      }

      .slider-tick-helpers {
        justify-content: space-between;
        flex-direction: column;
        white-space: nowrap;
        display: flex;

        @include mobile {
          flex-direction: row-reverse;

          > span {
            display: flex;
            flex: 0.45;
            white-space: normal;

            @include mobile {
              font-size: 14px;
            }

            &:first-child {
              text-align: right;
            }
          }
        }
      }
    }

    .slider-value {
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
      width: 100%;

      @include mobile {
        width: auto;
      }

      strong {
        font-size: 72px;
        display: block;
      }

      span {
        display: block;
        font-size: 18px;
      }
    }

    .rangeslider-vertical {
      border: 1px #bbb solid;
      border-radius: 10px;
      box-shadow: none;
      max-width: 24px;
      height: 100%;
      margin: 0 30px;
      min-height: inherit;

      .rangeslider__fill {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: #bbbbbb;
      }

      .rangeslider__labels {
        @include mobile {
          width: auto;
        }
      }
    }
  }

  .rangeslider-vertical .rangeslider__handle {
    border-radius: 100%;
    position: relative;
    outline: none;
    height: 50px;
    width: 50px;
    left: -15px;

    &:before {
      background: url('./../../../assets/icons/slider-handler.svg') no-repeat center;
      position: absolute;
      display: block;
      content: '';
      height: 100%;
      width: 100%;
    }
  }
}
