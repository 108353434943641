.titleContainer {
  margin: 10px;

  p {
    font-size: 16px;
  }
}

.optionsContainer {
  margin: 0 5px;
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }

  .radio {
    display: inline-flex;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #fff;
    border-width: 1px;
    border-color: #E5E5E7;
    margin-right: 10px;
  }
}


.questionNavigatorContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .paginationBtnIcon {
    width: 16px;
    height: 8px;
  }

  .paginationText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0;
    font-size: 18px;

    @include mobile {
      font-size: 16px;
    }

    .paginationFirst {
      color: #5FCD89;
    }

    .paginationSlash {
      color: #A5A5A5;
      padding: 0 2px;
    }

    .paginationLast {
      color: #A5A5A5;
    }
  }
}

.separatorContainer {
  max-width: calc(100vw - 400px);
  margin-bottom: 10px;
  margin-left: 5px;

  @include mobile {
    display: flex;
    max-width: 100%;
    margin-left: 0;
  }

  .separatorText {
    line-height: 28px;
    font-size: 20px;

    @include mobile {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.questionsContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  transition: .6s ease;

  &.questionsOut {
    opacity: 0;
  }

  &.questionsIn {
    opacity: 1;
  }

  @include mobile {
    width: 100%;
  }
}
