@import "~bulma/sass/base/minireset";
@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/grid/columns";
@import "~bulma/sass/elements/table";
@import "~bulma/sass/elements/button";
@import "~bulma/sass/components/pagination";
@import "~bulma/sass/elements/tag";
@import "~bulma/sass/helpers/typography";

@import "~bulma/sass/elements/container";

#root {
  min-height: inherit;

  > .container,
  > .background-layout {
    min-height: inherit;
    display: flex;
    flex-direction: column;
  }
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

#print-me {
  display: none;

  svg {
    width: 100%;
    height: 100%;
  }
}

@page {
  margin-top: 0cm;
  margin-bottom: 0cm;
}

@media print {
  @page {
    margin: 0;
  }

  html {
    background-color: #FFFFFF;
    margin: 0px;  /* this affects the margin on the html before sending to printer */
  }

  body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  footer {
    display: none;
    position: fixed;
    bottom: 0;
  }
  header {
    display: none;
    position: fixed;
    top: 0;
  }
}

@media print {
  html, body {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  #root {
    display: none;
  }

  #print-me {
    display: block;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: auto;
    }
  }

  body {min-width: auto !important;}

  @page {
    margin: 0cm !important;
  }
}

.flatpickr-monthDropdown-months {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}


.dynamic-typo {
  strong {
    font-weight: bold;
  }
}
